import Vue from 'vue';

import ToastService from 'primevue/toastservice';
import PrimeVue from 'primevue/config';

import Toast from 'primevue/toast';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';

Vue.use(PrimeVue, { ripple: true });
Vue.use(ToastService);

Vue.component('Toast', Toast);
Vue.component('InputText', InputText);
Vue.component('Button', Button);
