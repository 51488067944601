import Vue from "vue";
import { initializeApp } from "firebase/app";
import "bootstrap/dist/css/bootstrap.css";
import "primevue/resources/themes/saga-blue/theme.css";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";

import "@/assets/scss/app.scss";
import "@/assets/scss/element-ui.scss";
import router from "@/router";
import store from "@/store";
import "@/assets/plugins";
import { i18n } from "./i18n";
import App from "./App.vue";

Vue.config.productionTip = (process.env.NODE_ENV === 'production');

initializeApp(JSON.parse(process.env.VUE_APP_FIREBASE_OBJECT));

declare global {
  interface Window { 
    grecaptcha: any;
  }
}

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
