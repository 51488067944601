/* eslint-disable */
function validate(cnpj: string) {
  cnpj = cnpj.replace(/[^\d]+/g, '')

  let digit1Weight = [ 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2 ]
  let digit2Weight = [ 6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2 ]

  return geralVerification(cnpj) && verifyDigit(cnpj, digit1Weight) && verifyDigit(cnpj, digit2Weight)
}

function geralVerification (cnpj: any) {
  let excludeArray = [
    '00000000000000',
    '11111111111111',
    '22222222222222',
    '33333333333333',
    '44444444444444',
    '55555555555555',
    '66666666666666',
    '77777777777777',
    '88888888888888',
    '99999999999999'
  ]

  if (cnpj === '') return false;
  if (cnpj.length !== 14) return false;
  if (excludeArray.some(o => cnpj === o)) return false;

  return true;
}

function verifyDigit (cnpj: any, weights: any) {
  let numbers = cnpj.split('').slice(0, weights.length);
  // Sum CNPJ numbers based on weights
  let acumulator = numbers.reduce((previous: any, current: any, index: any) => {
    return previous + (current * weights[index]);
  }, 0);
  let rest = acumulator % 11;
  let digit = rest < 2 ? 0 : 11 - rest;
  return parseInt(cnpj[weights.length]) === digit;
}

export default validate;
