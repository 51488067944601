/* eslint-disable */
import ASCIIValidate from './ascii';

export const validator = {
  getMessage(field: any): string {
    return `The field ${field} must contain only ASCII characters`;
  },
  validate(value: any): boolean {
    return ASCIIValidate(value);
  },
};
