import { SystemWhitelabel } from "@/types"

export const defaultSystem: SystemWhitelabel = {
    tenant: "auth",
    brand: "AUTH",
    infos: {
        legalInformation: {
            privacy: "https://www.website.com.br/privacy",
            termsOfUse: "https://www.website.com.br/terms"
        },
        contacts: {
            support: "(99) 99999-9999"
        },
        socialMedia: {
            linkedin: "https://www.linkedin.com",
            facebook: "https://www.facebook.com",
            instagram: "https://www.instagram.com"
        },
        website: "https://www.website.com.br"
    },
    whitelabelConfigs: {
        colors: {
            primary: "#272646",
            secondary: "#4588E3"
        },
        icons: {
            favicon: "https://roit-whitelabel-assets.s3.sa-east-1.amazonaws.com/roit/favicon.ico"
        },
        images: {
            logo: {
                light: "https://roit-whitelabel-assets.s3.sa-east-1.amazonaws.com/localhost/logo-auth.png",
                dark: "https://roit-whitelabel-assets.s3.sa-east-1.amazonaws.com/localhost/logo-auth.png"
            },
            authBackground: "https://roit-whitelabel-assets.s3.sa-east-1.amazonaws.com/localhost/bg-web-system.png"
        }
    },
    services: []
}