import axios from "axios";

export default {
    handleReloadApp(state: any) {
        state.reloadApp = Math.random();
    },
    SET_TOKEN(state: any, token: string) {
        axios.defaults.headers.common['Authorization'] = token;

        state.token = token;
    },
    SET_APP_CHECK_INITIALIZED(state: any) {
        state.appCheckInitialized = true;
    }
}
