import { SystemWhitelabel } from "@/types";
import Vue from "vue";
import axios from 'axios';
import { defaultSystem } from "@/whitelabel/defaultSystem";

const whiteLabelSystem: SystemWhitelabel = defaultSystem

export default Vue.extend({
  name: 'WhitelabelMixin',
  data() {
    return {
      whiteLabelSystem,
    }
  },
  mounted() {
    this.loadWhitelabelConfig();
  },
  methods: {
    async loadWhitelabelConfig() {
      const [host] = window.location.host.split('/')

      try {
        const response = await axios.get(`https://roit-info-assets.s3.sa-east-1.amazonaws.com/${host}.system.json`);
        this.whiteLabelSystem = response.data;
        localStorage.setItem('tenant', this.whiteLabelSystem.tenant);
      } catch (_) {
        localStorage.setItem('tenant', 'auth');
      }
      //localStorage.setItem('system-info', JSON.stringify(this.whiteLabelSystem));

      const favicon = document.getElementById("favicon") as HTMLAnchorElement;
      favicon.href = this.whiteLabelSystem.whitelabelConfigs.icons.favicon;
      
      document.title = this.whiteLabelSystem.brand;
      document.documentElement.style.setProperty('--color-primary', this.whiteLabelSystem.whitelabelConfigs.colors.primary);
      document.documentElement.style.setProperty('$--color-primary', this.whiteLabelSystem.whitelabelConfigs.colors.primary);
      document.documentElement.style.setProperty('--color-secondary', this.whiteLabelSystem.whitelabelConfigs.colors.secondary);

      document.documentElement.style.setProperty(
        '--color-primary-hover',
        this.calculateHoverColor(this.whiteLabelSystem.whitelabelConfigs.colors.primary)
      );
      document.documentElement.style.setProperty(
        '--color-secondary-hover',
        this.calculateHoverColor(this.whiteLabelSystem.whitelabelConfigs.colors.secondary)
      );
    },
    calculateHoverColor(hexColor: string) {
      const rgbColor = this.hexToRgb(hexColor)
      const hoverRgb = {
        r: rgbColor.r - 20,
        g: rgbColor.g - 20,
        b: rgbColor.b - 20
      }
      return this.rgbToHex(hoverRgb)
    },
    hexToRgb(hexColor: string) {
      const bigintColor = parseInt(hexColor.slice(1), 16)
      const r = (bigintColor >> 16) & 255
      const g = (bigintColor >> 8) & 255
      const b = bigintColor & 255
      return { r, g, b }
    },
    rgbToHex(hoverRgb: { r: number, g: number, b: number }) {
      return `#${((1 << 24) + (hoverRgb.r << 16) + (hoverRgb.g << 8) + hoverRgb.b).toString(16).slice(1)}`
    }
  }
});