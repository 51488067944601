// import { App } from 'vue';
import Vue from 'vue';
import {
  ValidationProvider,
  ValidationObserver,
  extend,
  localize,
} from 'vee-validate';

import * as rules from 'vee-validate/dist/rules';

import ptBR from 'vee-validate/dist/locale/pt_BR.json';

import { i18n } from '@/i18n';

import {
  cnpj, ascii, cpf, ipv4, ipv6, port,
} from './rules';

localize('pt_BR', ptBR);

// eslint-disable-next-line
for (const [rule, validation] of Object.entries(rules)) {
  // @ts-ignore
  extend(rule, {
    ...validation,
  });
}

// Use custom error message for required errors
extend('required', {
  ...rules.required,
  message: i18n.t('requiredField') as string,
});

// Add CNPJ rule
extend('cnpj', {
  ...cnpj,
  message: i18n.t('invalidCNPJ') as string,
});

// Add ASCII rule
extend('ascii', {
  ...ascii,
  message: i18n.t('ascii') as string,
});

// Add CPF rule
extend('cpf', {
  ...cpf,
  message: i18n.t('invalidCPF') as string,
});

// Add IPv4 rule
extend('ipv4', {
  ...ipv4,
  message: i18n.t('ip') as string,
});

// Add IPv6 rule
extend('ipv6', {
  ...ipv6,
  message: i18n.t('ip') as string,
});

// Add Port rule
extend('port', {
  ...port,
  message: i18n.t('port') as string,
});

// export default (app: App) => {
//   app.component('ValidationProvider', ValidationProvider);
//   app.component('ValidationObserver', ValidationObserver);
// };
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
