/* eslint-disable */

function calcChecker1(firstNineDigits: any): number {
  let sum = 0;

  for (let i = 0; i < 9; i++) {
    sum += firstNineDigits.toString().charAt(i) * (10 - i);
  }

  const lastSumChecker1 = sum % 11;
  return (lastSumChecker1 < 2) ? 0 : 11 - lastSumChecker1;
}

function calcChecker2(cpfWithChecker1: any): number {
  let sum = 0;

  for (let i = 0; i < 10; i++) {
    sum += cpfWithChecker1.toString().charAt(i) * (11 - i);
  }
  const lastSumChecker2 = sum % 11;
  return (lastSumChecker2 < 2) ? 0 : 11 - lastSumChecker2;
}

function validate(value: string): boolean {
  const cleanCPF: string = value.replace(/[^\d]/g, '');
  const firstNineDigits = cleanCPF.substring(0, 9);
  const checker = cleanCPF.substring(9, 11);

  for (let i = 0; i < 10; i++) {
    if (firstNineDigits + checker === Array(12).join(i.toString())) {
      return false;
    }
  }

  const checker1 = calcChecker1(firstNineDigits);
  const checker2 = calcChecker2(`${firstNineDigits}${checker1}`);

  return (checker.toString() === checker1.toString() + checker2.toString());
}
export default validate;
