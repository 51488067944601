/* eslint-disable */
import IPv6 from './ipv6';

export const validator = {
  getMessage (field: any) { // will be added to default English messages.
    return 'Invalid IP'
  },
  validate (value: any) {
    return IPv6(value);
  }
}
