<template>
  <div ref="recaptcha" :id="recaptchaId"></div>
</template>

<script>
import { EventBus } from "@/services/EventBus";

export default {
  name: "Recaptcha",
  data() {
    return {
      recaptchaId: "recaptcha-container",
    };
  },
  mounted() {
    this.loadRecaptchaScript()
      .then(() => {
        this.renderRecaptcha();
      })
      .catch((error) => {
        console.error("Failed to load reCAPTCHA script:", error);
      });

    EventBus.$on("executeRecaptcha", async (callback) => {
      try {
        window.grecaptcha.reset();
        const token = await this.executeRecaptcha();
        callback(null, token);
      } catch (error) {
        callback(error, null);
      }
    });

    EventBus.$on("recaptchaResponse", (callback) => {
      callback(this.recaptchaResponse);
    });
  },
  beforeDestroy() {
    EventBus.$off("executeRecaptcha");
    EventBus.$off("executeRecaptchaResponse");
    EventBus.$off("recaptchaResponse");
  },
  methods: {
    async loadRecaptchaScript() {
      return new Promise((resolve, reject) => {
        if (document.getElementById("recaptcha-script")) {
          resolve();
          return;
        }

        const script = document.createElement("script");
        script.id = "recaptcha-script";
        script.src = "https://www.google.com/recaptcha/api.js?render=explicit";
        script.async = true;
        script.defer = true;
        script.onload = () => {
          if (window.grecaptcha) {
            window.grecaptcha.ready(() => {
              console.log("reCAPTCHA ready");
              resolve();
            });
          } else {
            reject(new Error("reCAPTCHA failed to load"));
          }
        };
        script.onerror = reject;
        document.head.appendChild(script);
      });
    },

    renderRecaptcha() {
      this.recaptchaWidgetId = window.grecaptcha.render(this.$refs.recaptcha, {
        sitekey: process.env.VUE_APP_GOOGLE_CAPTCHA_SITE_KEY,
        size: "invisible",
        callback: () => {
          this.recaptchaResponse = grecaptcha.getResponse();
          EventBus.$emit("executeRecaptchaResponse", this.recaptchaResponse);
        },
      });
    },

    executeRecaptcha() {
      return new Promise((resolve, reject) => {
        window.grecaptcha
          .execute(this.recaptchaWidgetId)
          .then(() => {
            EventBus.$on("executeRecaptchaResponse", (response) => {
              resolve(response);
            });
          })
          .catch(reject);
      });
    },
  },
};
</script>
