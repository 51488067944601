import Vue from "vue";
import VueRouter from "vue-router";
import MfaRoutes from "../views/Mfa/routes";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "login",
    alias: '/index.html',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login/Login.vue')
  },
  {
    path: "/forgot-password",
    name: "forgot-password",
    component: () => import(/* webpackChunkName: "forgot-password" */ '../views/ForgotPassword/ForgotPassword.vue')
  },
  {
    path: '/register-password',
    name: 'access-register-password',
    component: () => import(/* webpackChunkName: "access-register-password" */ '../views/RegisterPassword/RegisterPassword.vue')
  },
  {
    path: "/reset-password",
    name: "reset-password",
    props: {
      origin: 'reset',
    },
    component: () => import(/* webpackChunkName: "reset-password" */ '../views/RegisterPassword/RegisterPassword.vue')
  },
  {
    path: "/sign-out",
    name: "sign-out",
    component: () => import(/* webpackChunkName: "sign-out" */ '../views/Login/SignOut.vue')
  },
  {
    path: "/redirect",
    name: "redirect",
    component: () => import(/* webpackChunkName: "redirect" */ '../views/Login/Redirect.vue')
  },
  {
    path: "/sso",
    name: "sso",
    component: () => import(/* webpackChunkName: "redirect" */ '../views/Login/SSOLogin.vue')
  },
  ...MfaRoutes
];

const router = new VueRouter({
  mode: "history",
  routes,
});

export default router;
