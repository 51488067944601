/* eslint-disable */
// @ts-nocheck
import Vue from 'vue';
import VueI18n from 'vue-i18n';
import messages from '@/locales/pt-br';
import axios from 'axios';
import store from '@/store';
import elementEnLocale from 'element-ui/lib/locale/lang/en'; // element-ui lang
import elementEsLocale from 'element-ui/lib/locale/lang/es';// element-ui lang
import elementPtBrLocale from 'element-ui/lib/locale/lang/pt-br';// element-ui lang

Vue.use(VueI18n);

messages['pt-br'].el = elementPtBrLocale.el;

export const i18n = new VueI18n({
  locale: 'pt-br',
  fallbackLocale: 'pt-br',
  messages,
  silentTranslationWarn: true,
});

const loadedLanguages = ['pt-br, en, es'];

function setI18nLanguage(lang: string) {
  i18n.locale = lang;
  axios.defaults.headers.common['Accept-Language'] = lang;
  document.querySelector('html')?.setAttribute('lang', lang);
  return lang;
}

export function loadLanguageAsync(lang: string) {
  // If same language
  if (i18n.locale === lang || loadedLanguages.includes(lang)) {
    return Promise.resolve(setI18nLanguage(lang));
  }

  store.commit('handleLocaleChange', lang);
  // If language was already loaded
  if (loadedLanguages.includes(lang)) {
    return Promise.resolve(setI18nLanguage(lang));
  }

  // If language hasn't been loaded yet
  return import(`@/locales/${lang}.ts`).then(
    (message) => {
      let elLocale = elementPtBrLocale;
      switch (lang) {
        case 'pt-br':
          elLocale = elementPtBrLocale;
          break;
        case 'en':
          elLocale = elementEnLocale;
          break;
        case 'es':
          elLocale = elementEsLocale;
          break;
        default:
          elLocale = elementPtBrLocale;
      }
      

      i18n.setLocaleMessage(lang, Object.assign({}, message.default[lang], elLocale)); 
      loadedLanguages.push(lang);
      
      return setI18nLanguage(lang);
    },
  );
}
