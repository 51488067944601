/* eslint-disable */
import IPv4 from './ipv4';

export const validator = {
  getMessage (field: any) { // will be added to default English messages.
    return 'Invalid IP'
  },
  validate (value: any) {
    return IPv4(value);
  }
}
