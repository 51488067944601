/* eslint-disable */
import CNPJValidate from './cnpj';

export const validator = {
  getMessage (field: any) { // will be added to default English messages.
    return 'Invalid CNPJ'
  },
  validate (value: any) {
    return CNPJValidate(value);
  }
}
