/* eslint-disable */
import Port from './port';

export const validator = {
  getMessage (field: any) { // will be added to default English messages.
    return 'Invalid Port'
  },
  validate (value: any) {
    return Port(value);
  }
}
