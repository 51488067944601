<template>
  <div id="app">
    <Toast :base-z-index="1000" />
    <router-view></router-view>
    <RecaptchaV2 />
  </div>
</template>

<script lang="ts">
import mixins from "vue-typed-mixins";
import WhitelabelMixin from "@/mixins/WhitelabelMixin";
import RecaptchaV2 from "@/components/RecaptchaV2/Recaptcha.vue";

export default mixins(WhitelabelMixin).extend({
  name: "App",
  components: {
    RecaptchaV2,
  },
});
</script>
