import { getAuth } from "@firebase/auth";
import { NavigationGuardNext, Route, RouteConfig } from "vue-router";

const shouldBeLogged = async (to: Route, from: Route, next: NavigationGuardNext) => {
    if (process.env.VUE_APP_ENV !== 'sandbox') {
        const user = await getAuth().currentUser
        if (!user) next('/')
        else next()
    }
    else next()
}

export default [
    {
        path: '/mfa',
        component: () => import(/* webpackChunkName: "mfa" */ './MfaSteps.vue'),
        redirect: to => ({
            name: 'Select Type',
            query: { redirect: 'true' }
        }),
        children: [
            {
                path: 'select-type',
                name: "Select Type",
                component: () => import(/* webpackChunkName: "mfa" */ './StepSelectMfaType.vue'),
                beforeEnter: shouldBeLogged
            },
            {
                path: 'insert-token/:mfaType',
                name: "Insert Token",
                component: () => import(/* webpackChunkName: "mfa" */ './StepInsertToken.vue'),
                beforeEnter: shouldBeLogged
            },
            {
                path: 'register',
                name: "Register App",
                component: () => import(/* webpackChunkName: "mfa" */ './StepRegisterApp.vue'),
                beforeEnter: shouldBeLogged
            },
            {
                path: 'register/success',
                name: "Register App Success",
                component: () => import(/* webpackChunkName: "mfa" */ './StepRegisterAppSuccess.vue'),
                beforeEnter: shouldBeLogged
            }
        ]
    }
] as Array<RouteConfig>;