import { validator as cnpj } from './cnpj/cnpj.validator';
import { validator as ascii } from './ascii/ascii.validator';
import { validator as cpf } from './cpf/cpf.validator';
import { validator as ipv4 } from './ipv4/ipv4.validator';
import { validator as ipv6 } from './ipv6/ipv6.validator';
import { validator as port } from './port/port.validator';

export {
  cnpj, ascii, cpf, ipv4, ipv6, port,
};
