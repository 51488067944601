/* eslint-disable */
import CpfValidate from './cpf';

export const validator = {
  getMessage(field: any):string {
    return 'Invalid CPF';
  },
  validate(value: any):boolean {
    return CpfValidate(value);
  },
};
