import { LocaleMessages } from 'vue-i18n';

export const ptBr = {
    mfa: {
        title: "Dupla autenticação",
        description: "Escolha a forma que gostaria de obter um novo código de autenticação para o e-mail:",
        sendEmail: "Envie um e-mail para",
        emailSent: "Email enviado",
        selectApp: "Utilizar aplicativo autenticador",
        authenticate: "Autenticar",
        anotherAccount: "Entrar com outra conta",
        invalidToken: "Token inválido",
        errorTokenGeneric: "Erro ao enviar o token, tente novamente!",
        errorSendEmail: "Erro ao enviar o email, clique em 'reenviar código'.",
        insertTokenPage: {
            anotherMethod: "Escolher outro meio de autenticação",
            notReceivedToken: "Não recebeu o código?",
            resendToken: "Reenviar código",
            resendIn: "Reenvie em",
            writeCode: "Digite o código",
            typeEmailDescription: "Verifique a caixa de entrada ou spam e digite o código enviado para o e-mail:",
            typeAppDescription: "Digite o código que encontra-se em seu aplicativo de autenticação.",
            trustedDevice: "Adicionar dispositivo como confiável por 7 dias."
        },
        registerAppPage: {
            title: "Cadastro de dupla autenticação",
            description: "Identificamos que você ainda não habilitou a sua dupla autenticação por aplicativo. Vamos iniciar o cadastro.",
            registerApp: "Cadastrar Aplicativo",
            or: "ou",
            list: {
                first: "Baixe e instale em seu smartphone um aplicativo de autenticação para ",
                second: "Utilize o aplicativo de autenticação instalado e a câmera para digitalizar o código QR abaixo.",
                third: "Digite o código do seu aplicativo para cadastrar a dupla autenticação."
            },
        },
        registerAppSuccessPage: {
            title: "Dupla autenticação configurada",
            description: " Você cadastrou a dupla autenticação por aplicativo com sucesso. Em seu próximo acesso não será necessário cadastrar novamente.",
        }
    }
}

export const es = {
    mfa: {
        title: "Doble autenticación",
        description: "Elija la forma en que le gustaría obtener un nuevo código de autenticación para el correo electrónico:",
        sendEmail: "Enviar un correo electrónico a",
        selectApp: "Usar aplicación de autenticación",
        authenticate: "Autenticar",
        anotherAccount: "Iniciar sesión con otra cuenta",
        invalidToken: "Simbolo no valido",
        errorTokenGeneric: "¡Error al enviar el token, inténtalo de nuevo!",
        errorSendEmail: "Error al enviar el correo electrónico, haga clic en 'reenviar código'.",
        insertTokenPage: {
            anotherMethod: "Elija otro método de autenticación",
            notReceivedToken: "¿No recibiste el código?",
            resendToken: "Reenviar codigo",
            resendIn: "Reenviar a",
            writeCode: "Escribe el código",
            typeEmailDescription: "Revise su bandeja de entrada o correo no deseado e ingrese el código enviado al correo electrónico:",
            typeAppDescription: "Introduzca el código que se encuentra en su solicitud de autenticación.",
            trustedDevice: "Agregue el dispositivo como de confianza durante 7 días."
        },
        registerAppPage: {
            title: "Registro de doble autenticación",
            description: "Hemos identificado que aún no ha habilitado la doble autenticación de su aplicación. Comencemos el registro.",
            registerApp: "Solicitud de registro",
            or: "o",
            list: {
                first: "Descargue e instale una aplicación de autenticación en su teléfono inteligente para",
                second: "Use la aplicación de autenticación instalada y la cámara para escanear el código QR a continuación.",
                third: "Ingrese su código de solicitud para registrar la doble autenticación."
            },
        },
        registerAppSuccessPage: {
            title: "Autenticación dual configurada",
            description: "Ha registrado con éxito la autenticación dual por aplicación. En su próximo acceso, no necesitará volver a registrarse.",
        }
    }
}

export const en = {
    mfa: {
        title: "Double Authentication",
        description: "Choose the way you would like to get a new authentication code for the email:",
        sendEmail: "Send an email to",
        emailSent: "Email sent",
        selectApp: "Use authenticator app",
        authenticate: "Authenticate",
        anotherAccount: "Login with another account",
        invalidToken: "Invalid Token",
        errorTokenGeneric: "Error sending token, please try again!",
        errorSendEmail: "Error sending email, click 'resend code'.",
        insertTokenPage: {
            anotherMethod: "Choose another authentication method",
            notReceivedToken: "Didn't receive the code?",
            resendToken: "Resend code",
            resendIn: "Resend at",
            writeCode: "Type the code",
            typeEmailDescription: "Check your inbox or spam and enter the code sent to the email:",
            typeAppDescription: "Enter the code found in your authentication application.",
            trustedDevice: "Add device as trusted for 7 days."
        },
        registerAppPage: {
            title: "Double authentication registration",
            description: "We have identified that you have not yet enabled your app double authentication. Let's start the registration.",
            registerApp: "Register Application",
            or: "or",
            list: {
                first: "Download and install an authentication application on your smartphone to ",
                second: "Use the installed authentication app and camera to scan the QR code below.",
                third: "Enter your application code to register double authentication."
            },
        },
        registerAppSuccessPage: {
            title: "Dual authentication configured",
            description: "You have successfully registered the dual authentication per app. On your next access, you will not need to register again.",
        }
    }
}

const locales: LocaleMessages = {
    'pt-br': ptBr,
    es,
    en,
};

export default locales;